export const PGP_KEY = `mI0EZIzwlgEEANqnLJPE5aXBeXm/QQxfK+Ies0K7LvcB0l/u7qlTMDKURSO8BQIxl1YLT8JfGiAb0MIOlvHEKP4+MVb+IEWZWUIrnI/m6gaVVrnB8w1a1VDLUcHX8KuCWm1UE/0WfS46Ym/GaxYIPFraJIxaDhsW5NZhCnoFpkH2qSs054aCg/rxABEBAAG0J01hdWxpayBTZWhnYWwgPG1hdWxpa3NlaGdhbDdAZ21haWwuY29tPojRBBMBCAA7
FiEENlyp9QKMUYgTbYxC0sJPB/2et+0FAmSM8JYCGwMFCwkIBwICIgIGFQoJCAsCBBYCAwECHgcCF4AACgkQ0sJPB/2et+3QngP/aDpZkqRkQI3cOtOyX2EERby02DiJgJNFhsM/bLk+yZ+2G2GcMjS9XeW/3u/5N2TXdZ/euAKqf75k0mHQSmqDnkIpqN/OKYWp5USa4bOWFXs4A5U6JIz3fX6TMo8ulLgMS7t2EzzxGfSqnMtOn5X5seJrZbjh
iwGJ3DVX2ChV4QG4jQRkjPCWAQQA1JNwBfb8ec5GjmMiYtpGH6GYVWgXZQJOU8mYiuI2cgbn1tv/0rgwLIenUKemaF0Ag1i9TVudNCad9o5t++Apb5d9ymF9lOz6k9Q4OCyUbHNpuC0IhC1c9AZwkmOB9VYSMaS9iWODCtrpn9DqkqUB5yIfHqQAnSaRKOpr9Ur9Jb8AEQEAAYi2BBgBCAAgFiEENlyp9QKMUYgTbYxC0sJPB/2et+0FAmSM8JYC
GwwACgkQ0sJPB/2et+1nlgQAl5mnl/yTaI4ij3mamARy2aDqRjOMGKIShqHXqXMgLc7CAneUh+reXHILW7xDtixdfTgHNeDlvYUBijx0J6D70RbvFymK3JRAl5O8QiqstzK0GawBaGMu31HzRsDCWnQBej/c7KNqWjkghyra35kklTN4H+NcKoh0p8e3srptKhI=
=hkwm`;
export const RESUME = 'https://drive.google.com/file/d/1EgctBx0b2YZN14yyTCSOxzIjg1hOydzI/view?usp=sharing';
export const PGP_FILE_PATH = 'publickey.asc';
export const FINGERPRINT = `365CA9F5028C5188136D8C42D2C24F07FD9EB7ED`;
export const SECTION_TITLE = 'Maulik Sehgal.';
export const SECTION_SUBTITLE = 'Security, Automation, Cloud';
export const UTA_LINK = 'https://www.uta.edu';
export const GITHUB_LINK = 'https://www.github.com/sehgalmaulik';
export const LINKEDIN_LINK = 'https://www.linkedin.com/in/mauliksehgal';
export const EMAIL_LINK = 'mailto:mauliksehgal7@gmail.com';
export const TWITTER_LINK = 'https://twitter.com/magnusX86';
export const CREDLY_LINK = 'https://www.credly.com/users/maulik-sehgal/badges?sort=-state_updated_at';
