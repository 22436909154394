import React from 'react';
import './About.css';
import downloadIcon from '../assets/download.svg';
import * as c from './constants';

const formatFingerprint = (fingerprint) => {
    return fingerprint.match(/.{1,4}/g).reduce((acc, chunk, index) => {
        return acc + chunk + ((index + 1) % 2 === 0 ? '\n' : ' ');
    }, '');
};

const About = () => {
    const pgpKeyPreview = c.PGP_KEY;
    const filePath = c.PGP_FILE_PATH;
    const formattedFingerprint = formatFingerprint(c.FINGERPRINT);

    return (
        <div id="about" className="section">
            <div className="about-content">
                <div className="text-content">
                    <p className="section-title">{c.SECTION_TITLE}</p>
                    <div className="section-content">
                        <p className="section-subtitle">{c.SECTION_SUBTITLE}</p>
                        <p className="section-details">I build secure and efficient IT systems through automation, infrastructure management, and thoughtful problem-solving.</p>
                    </div>
                    <div className="resume-link-container">
                        <a href={c.RESUME} target="_blank" rel="noopener noreferrer" className="resume-link">
                            Resume
                        </a>
                    </div>

                </div>
                <a href={filePath} download className="pgp-key-link">
                    <div className="pgp-key">
                        <div className="inner-wrapper">
                            <div className="pgp-key-content">
                                {pgpKeyPreview}
                            </div>

                            <div className="download-icon" title="Download PGP Key">
                                <div className="fingerprint">{formattedFingerprint}</div>
                                <div className='download-wrapper'>
                                    <img src={downloadIcon} alt="download" />
                                    <span>PGP Key</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default About;
